.nav {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}

.swiper-nav-btn {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.animate-this-shit span {
    display: inline-block;
    opacity: 0%;
    white-space: pre;
}

:root {
    --dark-blue: #051328;
}

.nav-item-link::before,
.nav-item-link::after,
.footer-bottom-link::before,
.footer-bottom-link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.footer-bottom-link::before,
.nav-item-link::before {
    content: '';
    /* show by default */
}

.footer-bottom-link:hover span,
.nav-item-link:hover span {
    animation: glitchText 0.4s linear;
}

@keyframes glitchText {
    0% {
        opacity: 1;
        transform: translate3d(-10px, 0, 0) scale3d(-1, -1, 1);
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }

    10% {
        -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }

    20% {
        -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }

    35% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }

    50% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }

    60% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }

    70% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }

    80% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
        clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }

    90% {
        transform: translate3d(-10px, 0, 0) scale3d(-1, -1, 1);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

.footer-bottom-link::before,
.nav-item-link::before {
    height: 2px;
    opacity: 0;
}

.footer-bottom-link:hover::before,
.nav-item-link:hover::before {
    opacity: 1;
    animation: glitchLine 0.4s steps(2, start) forwards;
}

@keyframes glitchLine {
    0% {
        transform: scale3d(1, 1, 1);
    }

    10% {
        transform: translate3d(10px, 0, 0);
    }

    20% {
        transform: translate3d(0, 4px, 0);
    }

    30% {
        transform: scale3d(0.1, 1.4, 1) translate3d(0, -25px, 0);
        transform-origin: 100% 0%;
    }

    40% {
        transform: scale3d(1, 0.3, 1) translate3d(0, 25px, 0);
    }

    50% {
        transform: scale3d(0.5, 0.3, 1) translate3d(-100px, -80px, 0);
    }

    60% {
        transform: scale3d(1, 1.25, 1) translate3d(10px, -5px, 0);
    }

    70% {
        transform: scale3d(0.5, 0.5, 1) translate3d(0, 20px, 0);
    }

    80% {
        transform: translate3d(-30, 10px, 0) scale3d(1, 0.4, 1);
        transform-origin: 100% 0%;
    }

    90% {
        transform: scale3d(1, 0.5, 1) translate3d(0, -15px, 0);
        ;
        transform-origin: 0% 50%;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotateIt {
    -webkit-animation: rotating 20s linear infinite;
    -moz-animation: rotating 20s linear infinite;
    -ms-animation: rotating 20s linear infinite;
    -o-animation: rotating 20s linear infinite;
    animation: rotating 20s linear infinite;
}

.wlfwc-holder .wf {
    position: absolute;
    z-index: 2;
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    fill: currentColor;
}

.textcircle {
    position: relative;
    display: block;
    width: 200px;
}

.textcircle text {
    font-size: 32px;
    text-transform: uppercase;
    fill: white;
}

.textcircle textPath {
    letter-spacing: 17px;
    /* Firefox needs this */
}

.footer h2 .db {
    position: relative;
    z-index: 1;
}

.footer h2 .db::before {
    content: "";
    display: block;
    position: absolute;
    top: -100%;
    left: calc(50% - 100px);
    z-index: 0;
    width: 200px;
    height: 200px;
    background-image: url('https://uploads-ssl.webflow.com/6047999733515e4a9e0e1cc3/604900abca9dde39cf8ec086_notyourbusiness.gif');
    background-size: cover;
    background-position: 50% 50%;
    pointer-events: none;
    opacity: 0%;
    transition: 200ms all ease;
}

.footer h2 .db:hover::before {
    opacity: 100%;
}

.btn.submit {
    -webkit-clip-path: circle(40% at 50% 50%);
    clip-path: circle(40% at 50% 50%);
}

.submit-wrapper:hover .btn.submit {
    color: #00ffe1;
}

.submit:focus-visible {
    background: #00ffe1;
}

.btn.submit .textcircle {
    position: relative;
    display: block;
    width: 200px;
}

.btn.submit .textcircle text {
    font-size: 32px;
    text-transform: uppercase;
    fill: currentColor;
}

.btn.submit .textcircle textPath {
    letter-spacing: 17px;
    /* Firefox needs this */
}

.submit-wrapper:hover .btn.submit .textcircle {
    animation: rotateIt 7s linear infinite;
    fill: currentColor;
}

.btn.submit .rocket {
    position: absolute;
    z-index: 2;
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    fill: currentColor;
}

.submit-wrapper:hover .btn.submit .rocket {
    fill: currentColor;
}

@keyframes rotateIt {
    to {
        transform: rotate(-360deg);
    }
}

.heart {
    animation: heartbeat 3s infinite;
}

@keyframes heartbeat {
    0% {
        transform: scale(.75);
    }

    20% {
        transform: scale(1);
    }

    40% {
        transform: scale(.75);
    }

    60% {
        transform: scale(1);
    }

    80% {
        transform: scale(.75);
    }

    100% {
        transform: scale(.75);
    }
}

.swiper-nav-btn {
    /* outline: none; */
    cursor: pointer;
}
